import { baseUrl } from '@/src/util/constants'

const config = {
  title: 'slaps.audio',
  description: 'Remix and create slaps with ai',
  openGraph: {
    type: 'website',
    url: baseUrl,
    title: 'slaps.audio',
    description: 'Remix and create slaps with ai',
    defaultImageHeight: 630,
    defaultImageWidth: 1200,
    images: [
      {
        url: `${baseUrl}/images/slaps.jpeg`,
        width: 1200,
        height: 630,
        alt: 'slaps.audio'
      }
    ],
    site_name: 'slaps.audio',
    locale: 'en_US'
  },
  twitter: {
    cardType: 'summary_large_image',
    handle: '@createtriniti',
    site: '@createtriniti',
    title: 'slaps.audio',
    description: 'Remix and create slaps with ai',
    image: 'https://www.slaps.audio/images/slaps.jpeg'
  }
}

export default config
