export type SeedSkin = {
  id: string
  label: string
  video: string
  image: string
  bgColor: string
  textColor: string
}

export const premadeSkins: SeedSkin[] = [
  {
    label: 'CreateSafe',
    id: 'e663db4f-8350-469a-b6b8-06b4996d8616',
    video: 'https://dxmcho1ibljll4dc.public.blob.vercel-storage.com/blob-4neuZ8hDwjC3bhfbqRQ6hlW3qYsd1v.mp4',
    image: 'https://looper.triniti.plus/images/slaps.jpeg',
    bgColor: '#415afb',
    textColor: '#FFFFFF'
  },
  {
    label: 'i-wanna-be-software',
    id: '8705c7f3-de85-4633-9cbf-4f85cd08a0ad',
    video:
      'https://res.cloudinary.com/createsafe/video/upload/dpr_4.0,f_mp4,q_auto/v1/ipfs/bafybeiffopuvxlvfsmvpciebemnlp663kwgmsgmv2ufpgasmy4nrnlxyvi/349f045c333764e278089a000',
    image:
      'https://iautbgojk97mpntn.public.blob.vercel-storage.com/skin-og-images/grimes-UwUUxbXyLYDIN2cZ4fF3dyJqMVWsqi.jpg',
    bgColor: '#A60F0F',
    textColor: '#AEB9BF'
  },
  {
    label: 'Nas',
    id: '37f85fb0-9c0f-421d-a9f0-b45cadc06068',
    video:
      'https://res.cloudinary.com/createsafe/video/upload/dpr_4.0,f_mp4,q_auto/v1/ipfs/bafybeihbdfkwatgohjlqpgefpik5v2qvkfiyxna2y3nmrj6dxojw5ae5iu/1f4e0b44b7ab7d45049a5d200',
    image:
      'https://iautbgojk97mpntn.public.blob.vercel-storage.com/skin-og-images/nas-xdzxeNvLN3UUqRNAunQyaVpr8cJmoM.jpeg',
    bgColor: '#0D0D0D',
    textColor: '#AEB9BF'
  },
  {
    label: 'De La Soul',
    id: 'd0e9d73d-35a8-4d85-a9c4-3c80c5056d6b',
    video: 'https://dxmcho1ibljll4dc.public.blob.vercel-storage.com/delasoul-ENkWpkeKKEtBMZLaTjG2jBsJpYqpGP.mp4',
    image:
      'https://iautbgojk97mpntn.public.blob.vercel-storage.com/skin-og-images/delasoul-U8lQlQaEnQt90FFBwavNRbJ4lABuiq.jpeg',
    bgColor: '#F2E205',

    textColor: '#262526'
  },
  {
    label: 'Looper',
    id: '7de5055a-e92f-4fef-9f45-231a8c34fea3',
    video: 'https://dxmcho1ibljll4dc.public.blob.vercel-storage.com/looper-Xq4Kfo9FrGmdAMlJkFTlE3c1PXCd0k.mp4',
    image:
      'https://iautbgojk97mpntn.public.blob.vercel-storage.com/skin-og-images/looper-PjOle5WqTHNuAx494Fqy8rAEPnNwT5.jpg',
    bgColor: '#8C6658',

    textColor: '#F2F2F2'
  },
  {
    label: 'Chicago',
    id: 'c1c8740b-20b9-4b5a-a6f9-33d663f850f9',
    video: 'https://dxmcho1ibljll4dc.public.blob.vercel-storage.com/chicago-NHp9BsVh6vn3h2hg9GYdIbTx4CvLGV.mp4',
    image:
      'https://iautbgojk97mpntn.public.blob.vercel-storage.com/skin-og-images/chicago-4NHfSRrKI7bU0qwpKVRLInPSrkiPHq.jpg',
    bgColor: '#404040',
    textColor: '#D9D9D9'
  },
  {
    label: 'Rap Pack',
    id: 'ee4046c3-3d22-458d-a553-cd8f975a6773',
    video: 'https://dxmcho1ibljll4dc.public.blob.vercel-storage.com/jt2-R09odBid3HYLgYmUWXUeQhzVQ269iz.mp4',
    image:
      'https://iautbgojk97mpntn.public.blob.vercel-storage.com/skin-og-images/rappack-EiR0ekqXKQzwnAMnV2dMPksspUyS0A.jpg',
    bgColor: '#A6916A',
    textColor: '#3D3F3F'
  },
  {
    label: 'Groundhog Day',
    id: 'a34f9c28-f90e-4d2d-b7e1-e469867a1c25',
    video: 'https://dxmcho1ibljll4dc.public.blob.vercel-storage.com/groundhog-ioYYP8ryC4cPPA05FRMbnrEvBfvJGo.mp4',
    image:
      'https://iautbgojk97mpntn.public.blob.vercel-storage.com/skin-og-images/lovelyrita-C0rx6sEeWGdwgINtHb1s9BCyHkX0yv.jpeg',
    bgColor: '#402313',
    textColor: '#9D8D88'
  },
  {
    label: 'Blue Liquid',
    id: '608224ae-7b1b-4d1a-95e4-353d17b68780',
    video: 'https://dxmcho1ibljll4dc.public.blob.vercel-storage.com/mikos-da-gawd-pKOwI98exQydBGgqaPHw4UaAnlR0Sh.mp4',
    image:
      'https://iautbgojk97mpntn.public.blob.vercel-storage.com/skin-og-images/mdg-QmATt6fCuctF9r0hjVTV6008IPA5zH.jpg',
    bgColor: '#F285CC',
    textColor: '#403228'
  },
  {
    label: 'BBL Drizzy',
    id: '762aef14-2fef-4537-b618-2ac17baee299',
    video:
      'https://iautbgojk97mpntn.public.blob.vercel-storage.com/bblDrizzy/bad-blood-X4JfACHdZg3h9PbEw5aEN13VWntc4i.mp4',
    image:
      'https://iautbgojk97mpntn.public.blob.vercel-storage.com/bblDrizzy/bbl-drizzy-FnZSWlCvE5HQL4GNSyHLwvrcnJV4vD.jpg',
    bgColor: '#F2762E',
    textColor: '#FFFFFF'
  },
  {
    label: 'Million Dollar Baby',
    id: '676b5ffd-1cea-4353-9daf-a9146e4622e7',
    video: 'https://iautbgojk97mpntn.public.blob.vercel-storage.com/mdb/mo-cuishle-0lbUrg3QwkCVUtXSQTd0oc3SXJvSfh.mp4',
    image: 'https://iautbgojk97mpntn.public.blob.vercel-storage.com/mdb/Mo-Cuishle-uvbeadud0eXnY9IuIeQuusqkQVolsK.jpeg',
    bgColor: '#29705B',
    textColor: '#040203'
  },
  {
    label: 'Whatever She Wants',
    id: '538b7c0d-94c5-43fb-97d9-9a1d51539596',
    video:
      'https://iautbgojk97mpntn.public.blob.vercel-storage.com/whatever-she-wants/wsw-JROYCFoiQytOL8W1Ws7ePYlf1k4Gxz.mp4',
    image:
      'https://iautbgojk97mpntn.public.blob.vercel-storage.com/whatever-she-wants/wsw-DoBv6v8AxtogxAI8wyPs4Ocpnq7asJ.jpg',
    bgColor: '#4A8C5B',
    textColor: '#0D0D0D'
  },
  {
    label: 'Espresso',
    id: '79f62c06-1bbd-460d-b169-48606b2990b1',
    video:
      'https://iautbgojk97mpntn.public.blob.vercel-storage.com/espresso/espresso-uhmIAS6rkkZaJSlwAkhBLbJerRgOZu.mp4',
    image:
      'https://iautbgojk97mpntn.public.blob.vercel-storage.com/espresso/espresso-gCCoJHT3kEiH1hHKvMEMD4RF53v0XD.jpg',
    bgColor: '#36BFB1',
    textColor: '#0B1002'
  },
  {
    label: 'Carbonation',
    id: '55b40e6d-0655-4115-b183-4efb54a7b7bd',
    video:
      'https://iautbgojk97mpntn.public.blob.vercel-storage.com/carbonated/%20carbonated-kve9ue2JdscBdHXLPegP0pkKhEEklo.mp4',
    image:
      'https://iautbgojk97mpntn.public.blob.vercel-storage.com/carbonated/carbonated-APpkdMzK1RVIaXHy0MkaghWC2NInot.jpeg',
    bgColor: '#8C4A3B',
    textColor: '#F3E0D5'
  }
]
